import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import resolveLink from "~utils/resolveLink"

const StructuredData = ({ title, image, description, sku, brand, url, price, available, id }) => {

  const data = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": title,
		"id": id,
    "image": [
      image,
      ],
    "description": description,
    "sku": sku,
    "brand": {
      "@type": "Brand",
      "name": brand
    },
    "offers": {
      "@type": "Offer",
      "url": "https://tooprettytoburn.com.au/" + url,
      "priceCurrency": "AUD",
      "price": price,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": available ? `https://schema.org/InStock` : `https://schema.org/OutOfStock`
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <script 
        key={`structuredData`}
        type="application/ld+json"
      >
      {JSON.stringify(data)}
      </script>
    </Helmet>
  )
}

StructuredData.propTypes = {
  lang: PropTypes.string,
}

export default StructuredData
