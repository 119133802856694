import React from "react"
import Layout from "~components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import ProductComponent from "~components/ProductComponent"
import RenderBlock from "~components/RenderBlock"
import MakerBlock from "~components/MakerBlock"
import ProductGrid from "~components/ProductGrid"
import MakerSpotlight from "~components/MakerSpotlight"
import { css, Global } from "@emotion/react"
import CloudButton from "~components/CloudButton"
import CrinkleButton from "~components/CrinkleButton"
import resolveLink from "../utils/resolveLink"
import StructuredData from '~components/StructuredData'

const Product = ({ pageContext, data }) => {
  const {mobile} = breakpoints
  const { title, content, options } = data.product
	let slug = data.product.slug.current
  const maker = content?.maker
	const featutedMaker = data.sanitySiteSettings.featuredMaker

  let moreProducts = data.moreProducts.nodes.map(p => p.content._rawVariants)

  moreProducts = moreProducts.flat()

  return (
    <>
			<StructuredData 
				title={title}
				image={content?.image?.asset?.url}
				description={content?.seo?.metaDescription}
				sku={content?.shopify?.defaultVariant?.sku}
				brand={content?.maker?.title}
				url={resolveLink('product', slug)}
				price={content?.shopify?.defaultPrice}
				id={slug}
				available={content?.shopify?.defaultVariant?.inventoryPolicy === 'continue' ||content?.shopify?.defaultVariant?.inventoryQuantity > 0}
			/>
      <Seo
				title={title}
				image={content?.seo?.socialImage?.asset?.url}
				description={content?.seo?.metaDescription}
      />
      <Global styles={css`
        html{
          background-color: var(--white);
        }
      `}/>
      <ProductComponent 
        image={content?.animatedImage}
        name={content?.shopify?.title}
        maker={content?.maker?.title}
        shortDesc={content?.description}
        variants={content?.variants}
        price={content?.shopify?.defaultPrice}
        productOptions={options}
        content={content}
        slug={slug}
				video={content.video}
        // quantity={}
        id={content?.shopify?.productId}
      />
      {content?.Blocks?.map((block, i) => (
        <RenderBlock 
        content={block} 
        key={block?._key} 
        type={block?._type} 
        lastBlock={content?.Blocks.length - 1 === i}
      />
      ))}
      {maker && 
      <MakerBlock
        heading={maker?.title}
        to={maker?.slug?.current}
        shortDesc={maker?.content?.shortText}
        buttonText={maker?.content?.shortName}
        image={maker?.content?.headerImage}
        primary={false} 
        reverse={false}
        key={maker?.title}
        css={css`
        padding-top: 11rem;
        padding-bottom: 10rem;
        background-color: var(--beige);
        ${mobile}{
            padding-top: 8rem;
            padding-bottom: 8rem;
          }
        `}
      />
      }
      {moreProducts?.length > 0 && 
      <div css={css`
        padding-top: 11rem;
        ${mobile}{
            padding-top: 8rem;
        }
      `}>
        <h2 
          className='h1 serif'
          css={css`
            text-align: center;
            margin-bottom: 7rem;
        `}>
          More from {maker?.title}
        </h2>
        <ProductGrid products={moreProducts} />
      </div>
      }
      <CloudButton to="/shop">
          All products
      </CloudButton>
			{ maker.slug.current !== featutedMaker.slug.current &&
				<MakerSpotlight />
			}
      <CrinkleButton to="/makers" css={css`background: var(--beige);`}>
        All makers
      </CrinkleButton>
    </>
  )
}

export const query = graphql`
	query ProductQuery($slug: String, $makerSlug: String) {
		product: sanityProduct(slug: {current: {eq: $slug}}) {
			title
			slug {
				current
			}
			content: _rawContent(resolveReferences: {maxDepth:10})
			options: _rawOptions(resolveReferences: {maxDepth:5})
		}
		moreProducts: allSanityProduct(
    filter: {content: {maker: {slug: {current: {eq: $makerSlug}}}}}
  ){
      nodes {
        id
        content {
          _rawMaker(resolveReferences: {maxDepth: 2})
          _rawVariants(resolveReferences: {maxDepth: 5})
        }
      }
    }
	sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
			featuredMaker {
				slug {
					current
				}
			}
		}
	}
`

export default Product
